import React, {useCallback, useEffect, useReducer, useState} from "react";
import styled, {useTheme} from "styled-components";
import get from "lodash/get";
import useSite from "src/core/sites/hooks/useSite";
import useInStoreExperience, {
  IN_STORE_EXPERIENCE_CONFIG,
  IN_STORE_EXPERIENCE_VIEW_MAX_INDEX,
} from "src/integrations/inStoreExperience/hooks/useInStoreExperience";
import AllowCameraAccess from "src/integrations/inStoreExperience/components/AllowCameraAccess";
import * as Notifications from "src/core/notifications";
import Loader from "src/core/common/components/elements/Loader";
import useRouter from "src/core/common/hooks/useRouter";
import routes from "src/core/common/routes";
import QRCodeReader from "src/integrations/inStoreExperience/QRCodeReader";
import SwipeableViews from "react-swipeable-views";
import {ShoppingCartOutline} from "@styled-icons/evaicons-outline/ShoppingCartOutline";
import CartModalService from "src/core/checkout/components/cart/cartModalService";
import PrimaryButton from "src/core/common/components/elements/button/PrimaryButton";
import styles from "src/integrations/inStoreExperience/components/styles";
import useCustomHome from "src/integrations/inStoreExperience/hooks/useCustomHome";
import {CloseIcon} from "src/core/common/components/elements/button/CloseButton";
import useAuthentication from "src/core/authentication/hooks/useAuthentication";

const COUNTER_SIZE = "74px";
const HANDLERS = {
  gap: "8px",
  diameter: "12px",
};

export default function ProductReader() {
  const site = useSite();
  const options = site.getOptions();
  const [, authApi] = useAuthentication();
  const user = authApi.userProfile();
  const router = useRouter();
  const [, setDisplayCustomHome] = useCustomHome();

  const theme = useTheme();
  const _styles = styles(theme, site);

  const hasInStoreExperienceEventFlow = options.hasInStoreExperienceEventFlow();

  const [
    hasInStoreExperienceComplete,
    setHasInStoreExperienceComplete,
    getProductDetail,
    getProductDetailUrl,
  ] = useInStoreExperience();

  const [state, dispatch] = useReducer(reducer, {
    view: hasInStoreExperienceComplete ? IN_STORE_EXPERIENCE_VIEW_MAX_INDEX : 0,
    isCameraRequested: false,
    reader: null,
    showHelp: false,
    isPaused: true,
    decodedText: null,
    error: null,
  });

  const [cameraDimensions, setCameraDimensions] = useState({width: 300, height: 300});

  useEffect(() => {
    setCameraDimensions({
      width: window.innerWidth,
      height: window.innerHeight,
    });
    // eslint-ignore-next-line
  }, []);
  const onRefChange = useCallback(node => {
    if (node !== null) {
      dispatch({type: "INITIALIZE_READER"});
    }
  }, []);

  useEffect(() => {
    if (!state.reader) return;
    state.reader.checkCameraPermissions().then(hasPermissions => {
      hasPermissions && !!hasInStoreExperienceComplete
        ? dispatch({type: "REQUEST_CAMERA"})
        : dispatch({type: "STOP_REQUESTING_CAMERA"});
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.reader]);

  const closeInStoreExperience = (allowRestore = false) => {
    dispatch({type: "CLOSE_IN_STORE_EXPERIENCE"});

    if (state.reader) {
      state.reader.stop();
      dispatch({type: "NULLIFY_READER"});
    }

    setDisplayCustomHome(false, allowRestore);
  };

  const openProfile = () => {
    router.push(user ? routes.myAccount : routes.login);
  };

  const nextView = () => {
    if (state.view === IN_STORE_EXPERIENCE_VIEW_MAX_INDEX - 1) {
      setHasInStoreExperienceComplete(true);
    }
    dispatch({type: "NEXT_VIEW"});
  };

  useEffect(() => {
    if (state.view > IN_STORE_EXPERIENCE_VIEW_MAX_INDEX) {
      closeInStoreExperience();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.view]);

  const qrCodeSuccessCallback = useCallback(
    async decodedText => {
      if (state.isPaused || !state.reader) return;

      dispatch({type: "PAUSE_READER"});

      try {
        const url = new URL(decodedText);
        const productId = url.pathname
          .split("/")
          .filter(p => p)
          .slice(-1)[0]
          .split("-")
          .slice(-1);

        const productDetail = await getProductDetail(productId);
        await state.reader.stop();
        const productDetailUrl = getProductDetailUrl(productDetail);
        closeInStoreExperience(true);
        setTimeout(
          () =>
            router.push({
              pathname: productDetailUrl.pathname,
              params: {...productDetailUrl.params, instore_experience: true},
            }),
          10
        );
      } catch (e) {
        console.log(e);
        if (e.status === 404) {
          Notifications.error(
            "We couldn't find a product that matches the scanned QR code. Please double-check the code or try scanning again."
          );
        } else if (e?.message === "Failed to construct 'URL': Invalid URL") {
          Notifications.error("Unable to read QR Code.");
        } else {
          Notifications.error("Unexpected error has occurred");
        }
      }
    },
    // eslint-ignore-next-line
    [state.isPaused, state.reader, router]
  );

  useEffect(() => {
    if (state.isCameraRequested && state.reader && !state.reader.started) {
      let hideHelpTimeout = -1;
      const onStartSuccessCallback = decodedText => {
        dispatch({type: "SET_DECODED_TEXT", payload: decodedText});
        dispatch({type: "SHOW_HELP"});

        clearTimeout(hideHelpTimeout);
        hideHelpTimeout = setTimeout(() => {
          dispatch({type: "HIDE_HELP"});
        }, 1000);
      };

      const onErrorCallback = err => {
        Notifications.error(err.message);
        if (err.code === "failed_to_get_camera") {
          dispatch({type: "STOP_REQUESTING_CAMERA", payload: err});
        }
      };

      state.reader
        .start(onStartSuccessCallback, onErrorCallback)
        .then(() => {})
        .catch(() => {});
    }

    // eslint-disable-next-line
  }, [state.isCameraRequested]);

  useEffect(() => {
    if (state.decodedText) qrCodeSuccessCallback(state.decodedText);
  }, [state.decodedText, qrCodeSuccessCallback]);

  useEffect(() => {
    function handleTabFocus() {
      if (document.visibilityState === "visible") {
        if (state.reader && !state.isCameraRequested) {
          dispatch({type: "REQUEST_CAMERA"});
        }
      } else {
        setHasInStoreExperienceComplete(true);
        if (state.reader) {
          dispatch({type: "STOP_REQUESTING_CAMERA"});
          state.reader.stop();
        }
      }
    }

    document.addEventListener("visibilitychange", handleTabFocus);
    return () => {
      document.removeEventListener("visibilitychange", handleTabFocus);
    };
  }, [state.isCameraRequested, state.reader]);

  useEffect(() => {
    return () => {
      if (state.reader) {
        state.reader.stop();
      }
    };
  }, [state.reader]);

  const onCartButtonClickHandler = () => {
    CartModalService.show();
  };

  if (
    !options.inStoreExperienceEnabled() ||
    state.view > IN_STORE_EXPERIENCE_VIEW_MAX_INDEX
  ) {
    return null;
  }

  return (
    <MainContainer>
      {state.view !== IN_STORE_EXPERIENCE_VIEW_MAX_INDEX && !state.isCameraRequested && (
        <Container styles={_styles}>
          {!hasInStoreExperienceEventFlow && (
            <CloseButton onClick={closeInStoreExperience} />
          )}
          <img
            src={site.getLogoUrl()}
            alt={""}
            style={{maxWidth: 250, marginBottom: 16, maxHeight: 90}}
          />
          <ViewsContainer>
            <SwipeableViews
              index={state.view}
              onChangeIndex={newIndex => {
                dispatch({type: "SET_VIEW", payload: newIndex});
              }}
            >
              {[...Array(IN_STORE_EXPERIENCE_VIEW_MAX_INDEX).keys()].map(
                (slide, index) => {
                  const ImageComponent = IN_STORE_EXPERIENCE_CONFIG[index].image;
                  return (
                    <MiddleSection>
                      <Counter size={COUNTER_SIZE} styles={_styles.root}>
                        {index + 1}
                      </Counter>
                      <Content
                        pushUp={`${
                          (-1 * parseInt(COUNTER_SIZE.replace("px", ""))) / 2
                        }px`}
                      >
                        <Text view={index} styles={_styles.root} />
                        <ImageContainer>
                          <ImageComponent />
                        </ImageContainer>
                      </Content>
                    </MiddleSection>
                  );
                }
              )}
            </SwipeableViews>
            <ViewHandlersContainer>
              {[...Array(IN_STORE_EXPERIENCE_VIEW_MAX_INDEX).keys()].map(s => (
                <ViewHandler isSelected={s === state.view} styles={_styles.root} />
              ))}
            </ViewHandlersContainer>
          </ViewsContainer>
          <NextButton onClick={nextView}>
            {IN_STORE_EXPERIENCE_CONFIG[state.view].button}
          </NextButton>
        </Container>
      )}
      {state.view === IN_STORE_EXPERIENCE_VIEW_MAX_INDEX && !state.isCameraRequested && (
        <AllowCameraAccess
          hasInStoreExperienceEventFlow={hasInStoreExperienceEventFlow}
          goToMenu={() => setDisplayCustomHome(false)}
          onButtonClick={() => dispatch({type: "REQUEST_CAMERA"})}
          error={state.error}
        />
      )}
      <QRCodeContainer hide={!state.isCameraRequested} styles={_styles.button}>
        <div
          ref={onRefChange}
          id="reader"
          width={`${cameraDimensions.width}px`}
          height={`${cameraDimensions.height}px`}
        />
        <CustomLoader />
        <ActionsContainer>
          <MenuButtonContainer styles={_styles.menuButton}>
            {hasInStoreExperienceEventFlow ? (
              <MenuButton onClick={openProfile} label={"Profile"} />
            ) : (
              <MenuButton onClick={() => closeInStoreExperience()} label={"Menu"} />
            )}
          </MenuButtonContainer>
          <CartButtonContainer>
            <CartButton
              onClick={onCartButtonClickHandler}
              LeftIconComponent={ShoppingCartIcon}
              label={"Cart"}
            />
          </CartButtonContainer>
        </ActionsContainer>
        <ScanButtonContainer>
          {state.showHelp && (
            <HelpText>
              <span>Press to scan</span>
            </HelpText>
          )}
          <ScanButton
            onClick={() => dispatch({type: "RESUME_SCANNING"})}
            LeftIconComponent={!state.isPaused ? Loader : null}
            label={state.isPaused ? "Scan" : "Scanning..."}
          />
        </ScanButtonContainer>
      </QRCodeContainer>
    </MainContainer>
  );
}

function reducer(state, action) {
  const {type, payload} = action;
  switch (type) {
    case "NEXT_VIEW":
      return {...state, view: state.view + 1};
    case "SET_VIEW":
      return {...state, view: payload};
    case "REQUEST_CAMERA": {
      return {...state, isCameraRequested: true};
    }
    case "STOP_REQUESTING_CAMERA": {
      return {...state, isCameraRequested: false, error: payload};
    }
    case "INITIALIZE_READER": {
      return {...state, reader: new QRCodeReader()};
    }
    case "NULLIFY_READER": {
      return {...state, reader: null};
    }
    case "PAUSE_READER": {
      return {...state, isPaused: true};
    }
    case "SET_DECODED_TEXT": {
      return {...state, decodedText: payload};
    }
    case "SHOW_HELP": {
      return {...state, showHelp: true};
    }
    case "HIDE_HELP": {
      return {...state, showHelp: false};
    }
    case "RESUME_SCANNING": {
      return {...state, decodedText: null, isPaused: false};
    }
    case "CLOSE_IN_STORE_EXPERIENCE": {
      return {...state, view: 0, isOpen: false, isCameraRequested: false};
    }
    default:
      throw new Error("QR CODE ON IN STORE EXPERIENCE DISPATCH NOT DEFINED!");
  }
}

const Text = ({view, styles}) => (
  <TextContainer marginTop={`${parseInt(COUNTER_SIZE.replace("px", "")) / 2 + 16}px`}>
    <MainText styles={styles}>
      {get(IN_STORE_EXPERIENCE_CONFIG[view], "main", "")}
    </MainText>
    <SecondaryText styles={styles}>
      {get(IN_STORE_EXPERIENCE_CONFIG[view], "secondary", "")}
    </SecondaryText>
  </TextContainer>
);

const ShoppingCartIcon = styled(ShoppingCartOutline)`
  width: 24px;
  height: 24px;
`;
const CustomLoader = styled(Loader)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  z-index: -1;
`;
const MainContainer = styled.div`
  width: 100vw;
  height: 100vh; // necessary because firefox does not support dvh
  height: 100dvh;
  box-sizing: border-box;
  overflow: hidden;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  height: 100%;
  box-sizing: border-box;
  overflow-y: scroll;
  overflow-x: hidden;

  > *:last-child {
    position: fixed;
    max-width: 400px;
    box-sizing: border-box;
    padding: 24px;
    bottom: 0;

    * > button {
      background-color: ${({styles}) => styles.root.backgroundColor};
      border-radius: ${({styles}) => styles.button.borderRadius};
      font-weight: ${({styles}) => styles.button.fontWeight};
    }
  }
`;

const CloseButton = styled(CloseIcon)`
  position: absolute;
  right: 24px;
  top: 24px;
  cursor: pointer;
`;

const MiddleSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Counter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({size}) => size};
  height: ${({size}) => size};
  background-color: ${({styles}) => styles.backgroundColor};
  border-radius: 12px;
  color: #fff;
  font-size: 42px;
  z-index: 999;
`;

const Content = styled.div`
  position: relative;
  top: ${({pushUp}) => pushUp};
  background-color: #ebebeb;
  border-radius: 12px;
  box-shadow: 6px 8px 22px -11px rgba(0, 0, 0, 0.25);
  padding: 0 26px;
  width: 100%;
  max-width: 300px;
  min-height: 340px;
  z-index: 998;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
  margin-top: ${({marginTop}) => marginTop};
`;

const MainText = styled.span`
  font-family: ${({styles}) => styles.fontFamily};
  font-size: ${({styles}) => styles.mainTextFontSize};
  font-weight: ${({styles}) => styles.mainTextFontWeight};
  color: ${({styles}) => styles.mainTextColor};
`;

const SecondaryText = styled.span`
  font-family: ${({styles}) => styles.fontFamily};
  font-size: ${({styles}) => styles.secondaryTextFontSize};
  font-weight: ${({styles}) => styles.secondaryTextFontWeight};
  color: ${({styles}) => styles.secondaryTextColor};
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 24px;
`;

const ViewsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const ViewHandlersContainer = styled.div`
  display: flex;
  gap: ${HANDLERS.gap};
  position: relative;
  left: calc(
    50% -
      (
        (${HANDLERS.diameter} * ${IN_STORE_EXPERIENCE_VIEW_MAX_INDEX}) +
          (${HANDLERS.gap} * ${IN_STORE_EXPERIENCE_VIEW_MAX_INDEX - 1})
      ) / 2
  );
  width: 100vw;
`;

const ViewHandler = styled.span`
  height: 12px;
  width: 12px;
  background-color: ${({styles}) => styles.backgroundColor};
  border-radius: 50%;
  display: inline-block;
  opacity: ${({isSelected}) => `${isSelected ? 1 : 0.5}`};
`;

const QRCodeContainer = styled.div`
  display: ${({hide}) => (hide ? "none" : "flex")};
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  height: 100%;

  #reader video {
    width: 100vw !important;
  }

  * > button {
    font-weight: ${({styles}) => styles.fontWeight};
    border-radius: ${({styles}) => styles.borderRadius};
  }
`;

const ActionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
  position: absolute;
  padding: 0 20px;
  top: 24px;

  column-gap: 8px;
`;

const Button = styled(PrimaryButton)``;

const MenuButtonContainer = styled.div`
  width: 100px;
  height: 40px;

  * > button {
    background-color: ${({styles}) => styles.backgroundColor};
    color: ${({styles}) => styles.color};
    font-weight: ${({styles}) => styles.fontWeight};
  }
`;

const NextButton = styled(Button)``;

const MenuButton = styled(Button)``;

const CartButtonContainer = styled.div`
  width: 100px;
  height: 40px;

  * > button {
    display: flex;
    justify-content: space-evenly;
  }

  svg {
    color: ${({theme}) => theme.v2.color.onPrimary} !important;
  }
`;

const CartButton = styled(Button)`
  > svg {
    width: 24px;
    height: 24px;
  }
`;

const ScanButton = styled(Button)``;
const ScanButtonContainer = styled.div`
  position: absolute;
  width: 200px;
  bottom: 60px;
`;

const HelpText = styled.div`
  position: absolute;
  width: 100%;
  top: -100%;
  left: 50%;
  transform: translate3d(-25%, 0, 0);
  color: white;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
`;
